import { useTranslation } from 'react-i18next';
import { SegmentedControl } from '@mantine/core';
const ChangeLanguage = () => {
  const { i18n } = useTranslation();

  function changeLanguage(e: any) {
    i18n.changeLanguage(e.target.value);
  }

  return (
    <div className="ChangeLanguage">
      <SegmentedControl
        size="xs"
        onClick={changeLanguage}
        data={[
          { label: 'English', value: 'en' },
          { label: 'Thai', value: 'th' }
        ]}
      />
    </div>
  );
};

export default ChangeLanguage;
