import {
  createStyles,
  Image,
  Title,
  Group,
  Text,
  List,
  Grid,
  useMantineTheme,
  Tabs
} from '@mantine/core';
import ProfilePhoto from '../ProfilePhoto.jpg';
import ProfilePhoto1 from '../ProfilePhoto1.png';
import { useViewportSize } from '@mantine/hooks';
import Break from './Break';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = createStyles((theme) => ({
  content: {
    maxWidth: 800,
    marginRight: theme.spacing.xs,
    marginLeft: theme.spacing.xs,

    [theme.fn.smallerThan('md')]: {
      maxWidth: '97%'
    }
  },

  image: {
    marginRight: theme.spacing.xs,
    marginLeft: theme.spacing.xs,
    [theme.fn.smallerThan('md')]: {
      maxWidth: '95%'
    }
  }
}));

function HeroLanding() {
  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const [lineClampValue, setLineClampValue] = useState(15);
  const theme = useMantineTheme();
  const { t } = useTranslation();
  useEffect(() => {
    const head = document.querySelector('head') as HTMLHeadElement;
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
  }, []);

  const leftContent = (
    <div className={classes.content}>
      <Title order={4}>
        {t('name')}
        <Break isLine height={0.01} />
      </Title>
      <Tabs defaultValue="about">
        <Tabs.List>
          <Tabs.Tab value="about">{t('about_me')}</Tabs.Tab>
          <Tabs.Tab value="overview">{t('overview')}</Tabs.Tab>
          <Tabs.Tab value="bookings">{t('bookings')}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="about" pt="xs">
          <Text lineClamp={lineClampValue} size={'sm'}>
            <p>{t('about_1')}</p>
            <p>{t('about_2')}</p>
            {t('about_3')}
            <p>{t('about_4')}</p>
            {t('about_5')}
            <p>{t('about_6')}</p>
            <p>{t('about_7')}</p>
          </Text>

          {lineClampValue === 0 ? (
            <Text
              size={'sm'}
              style={{
                textAlign: 'right',
                cursor: 'pointer'
              }}
              mr={5}
              color="dimmed"
              onClick={() => setLineClampValue(15)}>
              {t('read_less')}
            </Text>
          ) : (
            <Text
              size={'sm'}
              style={{
                textAlign: 'right',
                cursor: 'pointer'
              }}
              mr={5}
              color="dimmed"
              onClick={() => setLineClampValue(0)}>
              {t('read_more')}
            </Text>
          )}
        </Tabs.Panel>
        <Tabs.Panel value="overview" pt="xs">
          <List size="md">
            <b>{t('education')}</b>
            <Break isLine height={0.01} />
            <List size="sm">
              <List.Item>{t('education_1')}</List.Item>
            </List>
            <Break />
            <b>{t('experience')}</b> <Break isLine height={0.01} />
            <List size="sm">
              <List.Item>{t('experience_1')}</List.Item>
              <List.Item>{t('experience_2')}</List.Item>
              <List.Item>{t('experience_3')}</List.Item>
            </List>
            <Break />
            <b>{t('teaching_style')}</b> <Break isLine height={0.01} />
            <List size="sm">
              <List.Item>{t('teaching_style_1')}</List.Item>
              <List.Item>{t('teaching_style_2')}</List.Item>
              <List.Item>{t('teaching_style_3')}</List.Item>
            </List>
          </List>
        </Tabs.Panel>
        <Tabs.Panel value="bookings" pt="xs">
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/studywbaifern9463?hide_gdpr_banner=1"
            style={{ minWidth: '100%', height: '100vh' }}></div>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
  if (width <= 1200) {
    return (
      <Grid>
        <Grid.Col>
          <Break></Break>
          <Group align="center">
            <Image height={150} src={ProfilePhoto1} className={classes.image} />
          </Group>
        </Grid.Col>
        <Grid.Col lg={8}>{leftContent}</Grid.Col>
      </Grid>
    );
  }
  return (
    <Grid>
      <Grid.Col lg={8}>{leftContent}</Grid.Col>
      <Grid.Col lg={4}>
        <Image height={510} src={ProfilePhoto} className={classes.image} />
      </Grid.Col>
    </Grid>
  );
}

export default HeroLanding;
