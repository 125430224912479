import { MantineThemeOverride } from "@mantine/core";

const themeDark: MantineThemeOverride = {
  colorScheme: "dark",
  colors: {
    gold: ['#ECD662'],
    green: ['#5D8233'],
    blue: ['#284E78'],
    four: ['#3E215D'],
  },
  // dark: [
  //           '#d5d7e0',
  //           '#acaebf',
  //           '#8c8fa3',
  //           '#666980',
  //           '#4d4f66',
  //           '#34354a',
  //           '#2b2c3d',
  //           '#1d1e30',
  //           '#0c0d21',
  //           '#01010a',
  //         ],
  };


const themeLight: MantineThemeOverride = {
  colorScheme: "light",
  colors: {
    gold: ['#ECD662'],
    green: ['#5D8233'],
    blue: ['#284E78'],
    four: ['#3E215D'],
    //  light: [
    //         '#d5d7e0',
    //         '#acaebf',
    //         '#8c8fa3',
    //         '#666980',
    //         '#4d4f66',
    //         '#34354a',
    //         '#2b2c3d',
    //         '#1d1e30',
    //         '#0c0d21',
    //         '#01010a',
    //       ],
  },
 
  };

export {themeDark, themeLight}