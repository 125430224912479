import { Footer, Group, MantineProvider, Stack, Button, BackgroundImage } from '@mantine/core';
import {
  IconMail,
  IconPhone,
  IconCheck,
  IconCopy,
  IconBrandLinkedin,
  IconDownload
} from '@tabler/icons';
import { Link } from 'react-router-dom';

import HeroLanding from './components/HeroLanding';
import { ColorSchemeToggle } from './components/ColorSchemeToggle/ColorSchemeToggle';
import { useState } from 'react';
import { themeDark, themeLight } from './themes';
import SocialLinks from './components/SocialLinks';
import Break from './components/Break';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from './components/ChangeLanguage';
import { useClipboard } from '@mantine/hooks';
import background from './background.png';
import { useMediaQuery } from '@mantine/hooks';

export default function App() {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [theme, setTheme] = useState(themeDark);
  const toggleTheme = (value: any) => {
    const nextColorScheme = value || (theme === themeDark ? themeLight : themeDark);
    setTheme(nextColorScheme);
  };
  const clipboard = useClipboard({ timeout: 500 });
  const { t } = useTranslation();
  // const myStyle = {
  //   backgroundImage: `url(${background})`,
  //   backgroundPosition: 'center',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: '150% 150%',
  //   height: '150vh' // Add this line
  // };

  return (
    // <div style={myStyle}>
    <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
      {isMobile ? <Break height={2}></Break> : <Break height={5}></Break>}

      <Group position="center">
        <ColorSchemeToggle toggleTheme={toggleTheme}></ColorSchemeToggle>
        <ChangeLanguage></ChangeLanguage>
        {/* <SocialLinks></SocialLinks>  */}
      </Group>
      <Stack align="center" mt={20} spacing="xs">
        <HeroLanding></HeroLanding>
      </Stack>
      <Break height={4}></Break>
      {/* <Footer fixed height={50}> */}
      {/* Footer div fixed */}
      <div style={{ position: 'fixed', bottom: 0, width: '100%', marginBottom: 10, marginTop: 10 }}>
        {/* <BackgroundImage src={background} radius="md"> */}
        <Group position="center" spacing="xs" mt={10}>
          <Link to="/Baifern_Resume.pdf" target="_blank" download>
            <Button
              variant="default"
              leftIcon={<IconDownload size={14}></IconDownload>}
              radius="md"
              compact>
              CV
            </Button>
          </Link>
          <a target="_blank" onClick={() => clipboard.copy('+66802894653')} rel="noreferrer">
            <Button
              leftIcon={<IconPhone size={14}></IconPhone>}
              variant="default"
              radius="md"
              compact>
              {clipboard.copied ? (
                <IconCheck size={14}></IconCheck>
              ) : (
                <IconCopy size={14}></IconCopy>
              )}
            </Button>
          </a>
          <a target="_blank" href="https://www.linkedin.com/in/sopharas/" rel="noreferrer">
            <Button variant="default" radius="md" compact>
              <IconBrandLinkedin size={14}></IconBrandLinkedin>
            </Button>
          </a>
          <a
            target="_blank"
            onClick={() =>
              window.open(
                'mailto:studywbaifern9463@gmail.com?subject=Tutoring%20Enquiry&body=Hi%20Baifern%20I%20would%20like%20to%20enquire%20about%20tutoring%20services.'
              )
            }
            rel="noreferrer">
            <Button variant="default" radius="md" compact>
              <IconMail size={14}></IconMail>
            </Button>
          </a>
        </Group>
        {/* </BackgroundImage> */}
      </div>
    </MantineProvider>
    // </div>
  );
}
